@tailwind base;
@tailwind components;
@tailwind utilities;

/* Montserrat 폰트 (영문, 숫자용) */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

/* GmarketSans 폰트 (제목용) */
@font-face {
  font-family: 'GmarketSans';
  font-weight: 700;
  font-display: swap;
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');
}

@font-face {
  font-family: 'GmarketSans';
  font-weight: 500;
  font-display: swap;
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
}

/* Pretendard 폰트 (본문용) */
@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-display: swap;
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
}

/* 기본 스타일 */
@layer base {
  body {
    @apply font-sans text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-900;
    word-break: keep-all;
    overflow-wrap: break-word;
  }
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-title text-gray-900 dark:text-white;
    word-break: keep-all;
    overflow-wrap: break-word;
  }
  
  h1 {
    @apply text-heading-1;
  }
  
  h2 {
    @apply text-heading-2;
  }
  
  h3 {
    @apply text-heading-3;
  }
  
  p {
    word-break: keep-all;
    overflow-wrap: break-word;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.word-break-keep-all {
  word-break: keep-all;
  overflow-wrap: break-word;
}

/* 모바일 및 태블릿 환경에서의 텍스트 최적화 */
@media (max-width: 768px) {
  .responsive-text {
    word-break: keep-all;
    overflow-wrap: break-word;
    hyphens: auto;
  }
}